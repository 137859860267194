import React, { useEffect, useContext, useState } from 'react';
import { joinWithInviteKey } from '../../database/groups';
import { useParams, Redirect } from 'react-router-dom';
import { UserContext } from '../../database/user';


function Invited() {
  const [redirect, setRedirect] = useState(false);
  const { groupKey, inviteKey } = useParams();
  const { uid } = useContext(UserContext).data;

  useEffect(() => {
    console.log('run join', groupKey, uid, inviteKey);
    joinWithInviteKey({ groupKey, userKey: uid, inviteKey })
      .then((result) => {
        setRedirect(true);
      })
      .catch(() => {
        setRedirect(true);
      })
  }, [groupKey, uid, inviteKey]);

  if (redirect) {
    return (
      <Redirect to={`/g/${groupKey}`} />
    );
  }

  return (
    <div className="mt-10 text-gray-500 text-center">
      <i className="fas fa-cog fa-spin" /> invited..
    </div>
  );
}

export default Invited;
