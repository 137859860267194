import React from 'react';
import { db, functions } from '.';


export const useGroupsByUser = (uid) => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      if (!uid) {
        return;
      }
      setLoading(true);
      setError(false);
      const unsubscribe = db
        .collection('groups')
        .where('memberKeys', 'array-contains', uid)
        .onSnapshot((snapshot) => {
          const newData = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            newData.unshift({
              key: doc.id,
              ...data,
            });
          });
          setData(newData);
          setLoading(false);
        }, (err) => {
          console.log(err);
          setLoading(false);
          setError(err);
        });
      return () => unsubscribe();
    },
    [uid],
  );

  return {
    error,
    loading,
    data,
  };
};


export const useGroup = (groupKey) => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      if (!groupKey) {
        return;
      }
      setLoading(true);
      setError(false);
      const unsubscribe = db
        .collection('groups')
        .doc(groupKey)
        .onSnapshot((doc) => {
          setData(doc.data());
          setLoading(false);
        }, (err) => {
          console.log(err);
          setLoading(false);
          setError(err);
        });
      return () => unsubscribe();
    },
    [groupKey],
  );

  return {
    error,
    loading,
    data,
  };
};


// join a group with an invite key
export const joinWithInviteKey = ({ groupKey, userKey, inviteKey }) => {
  return functions.httpsCallable('joinWithInviteKey')({ groupKey, userKey, inviteKey });
};


// Create new group
/*
export const createGroup = ({ uid, userName, userEmail, groupName }) => {
  db.collection('groups').add({
    name: groupName,
    created: Date.now(),
    creator: uid,
    adminKeys: [uid],
    memberKeys: [uid],
    members: [
      {
        uid: uid,
        name: userName,
        email: userEmail,
      }
    ]
  });
};
*/