import React from 'react';
import { db } from '.';

export const UserContext = React.createContext({
  user: null,
});

export const useUser = (uid) => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      if (!uid) {
        setData(null);
        return;
      }
      setLoading(true);
      const unsubscribe = db
        .collection('users')
        .doc(uid)
        .onSnapshot((doc) => {
          const data = doc.data();
          setData({
            uid,
            ...data,
          });
          setLoading(false);
        }, (err) => {
          setLoading(false);
          setError(err);
        });
      return () => unsubscribe();
    },
    [uid],
  );

  return {
    error,
    loading,
    data,
  };
};
