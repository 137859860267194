import React from 'react';
import firebase from 'firebase';
import 'firebase/auth';

const provider = new firebase.auth.GoogleAuthProvider();


export const login = () => {
  firebase.auth().signInWithPopup(provider);
};

export const logout = () => {
  firebase.auth().signOut();
};

export const AuthContext = React.createContext({
  data: null,
});

export const useAuth = () => {
  const [state, setState] = React.useState(() => {
    const auth = firebase.auth().currentUser;
    return {
      loading: !auth,
      data: auth,
    };
  });
  
  function onChange(auth) {
    setState({
      loading: false,
      data: auth,
    });
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);

  return state;
};