import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/functions';

export const config = {
  apiKey: 'AIzaSyBk2rd_px-KZeAm9in4rBPDAjuG76HhFJM',
  authDomain: 'bring-your-own-news.firebaseapp.com',
  databaseURL: 'https://bring-your-own-news.firebaseio.com',
  projectId: 'bring-your-own-news',
  storageBucket: 'bring-your-own-news.appspot.com',
  messagingSenderId: '561228570609',
  appId: '1:561228570609:web:d285e08cad8148b0bbae50',
  measurementId: 'G-FN34TTX4LV'
}

firebase.initializeApp(config);

// firebase.firestore().enablePersistence();

export const functions = firebase.functions();
export const db = firebase.firestore();
