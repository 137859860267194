import React from 'react';
import UserApp from '../user-app';
import { useAuth, AuthContext } from '../../database/auth';
import Header from '../header';
import { useUser, UserContext } from '../../database/user';

function App() {
  const auth = useAuth();
  const uid = auth && auth.data && auth.data.uid ? auth.data.uid : null;
  const user = useUser(uid);

  if (auth.loading === true) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" /> auth
      </div>
    );
  }

  if (user.loading === true) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" /> user
      </div>
    );
  }

  // Return alleen als er een auth is.
  return (
    <AuthContext.Provider value={auth}>
      <UserContext.Provider value={user}>
        <div className="flex flex-col h-full">
          <Header />
          <UserApp />
        </div>
      </UserContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
