import React from 'react';
import { login } from '../../database/auth';

function LoggedOut() {

  return (
    <div className="flex-grow flex flex-col">
      <div className="max-w-5xl mx-auto w-full mt-32 px-5">
        <div className="shadow md:flex rounded-lg">
          <div className="md:w-1/2 p-12 text-white rounded-t-lg md:rounded-tr-none md:rounded-l-lg" style={{ background: '#0D5C63' }}>
            <div className="mb-3">Conversations are getting shorter, less nuanced, and a maybe bit boring even.</div>
            <div className="mb-3">Challenge your friends, colleagues and family to write something more creative!</div>
            <div className="mb-3">With <span className="italic">Bring Your Own News</span>, you create a private group, and everyone can write their piece until the weekly deadline - then all parts are put together into one newsletter and sent to all members.</div>
            <div className="font-bold">What are you going to write?</div>
          </div>
          <div className="md:w-1/2 bg-white p-12 rounded-b-lg md:rounded-bl-none md:rounded-r-lg flex content-center items-center">
            <button
              type="button"
              className="mx-auto inline-block leading-tight bg-gray-200 hover:bg-gray-300 rounded px-6 py-4 focus:outline-none"
              onClick={() => {
                login();
              }}
            >
              Login / Sign up with Google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoggedOut;
