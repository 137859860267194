import React from 'react';
import moment from 'moment';
import { UserContext } from '../../database/user';
import { useContext } from 'react';
import { useGroupsByUser } from '../../database/groups';
import { Link } from 'react-router-dom';


function UserGroups() {
  const {data: user} = useContext(UserContext);
  const {loading, data: groups} = useGroupsByUser(user.uid);

  if (loading) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" /> loading groups
      </div>
    )
  }

  if (!groups) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        No groups found.
      </div>
    )
  }

  return (
    <div>
      <div className="my-10 text-xl lg:text-3xl flex justify-center items-center font-bold">
        My Groups
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 break-words">
        {
          !!groups
          && !!groups.length
          && groups.map((group) => {
            const { name, key, headerImage, deadline } = group;

            return (
              <Link
                key={key}
                to={`/g/${key}`}
                className="block bg-white rounded shadow hover:shadow-lg"
              >
                <div
                  className="z-10 rounded-t relative w-full"
                  style={{
                    background: `linear-gradient(rgba(20,20,20, .5), rgba(20,20,20, .5)), url(${headerImage || 'https://unsplash.com/photos/0YP8RTiIv_g/download?force=true&w=1920'}) no-repeat center center`,
                    backgroundSize: 'cover',
                    overflow: 'hidden',
                    paddingTop: '50%',
                  }}
                >
                  <div className="text-xl lg:text-3xl absolute w-full h-full top-0 left-0 flex justify-center items-center text-white font-bold text-center">
                    { name }
                  </div>
                </div>
                <div className="p-3">
                  {
                    !!deadline
                    && !!deadline.E
                    && !!deadline.HH
                    && `Deadline: ${moment().isoWeekday(deadline.E).format('dddd')} @ ${deadline.HH}:00`
                  }
                </div>
              </Link>
            )
          })
        }
      </div>
    </div>
  );
}

export default UserGroups;
