import moment from 'moment';

const TipTapMenu = ({ editor, lastUpdated, editing }) => {
  if (!editor) {
    return null;
  }

  return (
    <div class="bg-white p-2 border-2 rounded-t-md text-gray-500 text-sm flex flex-wrap items-center">

      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("bold") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-bold" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("italic") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-italic" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("strike") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-strikethrough" />
      </button>

      <div class="bg-gray-200 ml-1 mr-1" style={{ width: '1px', height: '1rem' }} />

      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("heading", { level: 1 }) ? "bg-gray-700 text-white" : ""}`}
      >
        H1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("heading", { level: 2 }) ? "bg-gray-700 text-white" : ""}`}
      >
        H2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("heading", { level: 3 }) ? "bg-gray-700 text-white" : ""}`}
      >
        H3
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("paragraph") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-paragraph" />
      </button>

      <div class="bg-gray-200 ml-1 mr-1" style={{ width: '1px', height: '1rem' }} />

      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("bulletList") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-list-ul" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("orderedList") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-list-ol" />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("codeBlock") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-code" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        class={`px-2 py-1 rounded hover:bg-gray-700 hover:text-white focus:outline-none ${editor.isActive("blockquote") ? "bg-gray-700 text-white" : ""}`}
      >
        <i class="far fa-quote-right" />
      </button>

      {
        !!lastUpdated
        && (
          <>
            <div class="bg-gray-200 ml-1 mr-1" style={{ width: '1px', height: '1rem' }} />

            <div className="ml-auto flex items-center">
              <div>
                {
                  !!editing
                  && (
                    <div className="px-2 py-1 rounded bg-yellow-200 text-yellow-800 focus:outline-none">
                      <i className="fal fa-marker"></i>
                    </div>
                  )
                }
                {
                  !editing
                  && (
                    <div className="px-2 py-1 rounded bg-green-200 text-green-800 focus:outline-none">
                      <i className="far fa-check"></i>
                    </div>
                  )
                }
              </div>
              <div className="ml-2 text-xs">
                { moment(lastUpdated).format('D/M/YYYY HH:mm:ss') }
              </div>
            </div>
          </>
        )
      }


    </div>
  );
};

export default TipTapMenu;