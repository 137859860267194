import React from 'react';
import { db } from '.';


// update draft
export const updateDraft = ({ uid, groupKey, content, lastUpdated }) => {
  return db.collection('groups').doc(groupKey).collection('drafts').doc(uid).set({
    lastUpdated,
    content,
  });
}


// Get newsletter
export const useDraft = ({ uid, groupKey }) => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  React.useEffect(
    () => {
      if (!uid) {
        return;
      }
      setLoading(true);
      setError(false);
      const unsubscribe = db
        .collection('groups')
        .doc(groupKey)
        .collection('drafts')
        .doc(uid)
        .onSnapshot((doc) => {
          setData(doc.data());
          setLoading(false);
        }, (err) => {
          console.log(err);
          setLoading(false);
          setError(err);
        });
      return () => unsubscribe();
    },
    [uid, groupKey],
  );

  return {
    error,
    loading,
    data,
  };
};