import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../database/user';
import UserGroups from '../user-groups';
import Group from '../group';
import Invited from '../invited';
import LoggedOut from '../logged-out';

function UserApp() {
  const {data: user} = useContext(UserContext);

  // return homepage if no user.
  if (!user || !user.uid) {
    return <LoggedOut />
  }

  return (
    <div className="max-w-5xl mx-auto px-5 w-full">
      <Router>
        <Switch>
          <Route path="/g/:groupKey/i/:inviteKey">
            <Invited />
          </Route>
          <Route path="/g/:groupKey">
            <Group />
          </Route>
          <Route path="/">
            <UserGroups />
          </Route>
        </Switch>
      </Router>
      <div className="h-64" />
    </div>
  );
}

export default UserApp;
