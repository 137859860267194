import React from 'react';
import PropTypes from 'prop-types';


function Members(props) {
  const { members } = props;

  return (
    <div>
      <div className="font-bold my-3">Members</div>
      <pre className="hidden">{JSON.stringify({ members }, null, 2) }</pre>
      {
        !!members
        && !!members.length
        && members.map((member) => {
          const { name, uid, email } = member;
          return (
            <div key={uid} className="flex items-center mb-2">
              <div>{ name }</div>
              <div class="text-xs text-gray-400 ml-2">{ email }</div>
            </div>
          )
        })
      }
    </div>
  );
}

Members.propTypes = {
  members: PropTypes.array.isRequired,
};


export default Members;
