import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { UserContext } from '../../database/user';
import { updateDraft, useDraft } from '../../database/drafts';
import TipTapMenu from './tiptap-menu';


function Draft(props) {
  const { groupKey } = props;
  const { uid } = useContext(UserContext).data;
  const {loading, data: draft} = useDraft({ uid, groupKey });
  const timeoutRef = useRef(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [editing, setEditing] = useState(false);


  // Init TipTap
  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    content: '',
    editorProps: {
      attributes: {
        class: "text-gray-700 focus:outline-none"
      }
    },
    onUpdate({ editor }) {
      const newLastUpdated = Date.now();
      setEditing(true);
      setLastUpdated(newLastUpdated);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        const content = editor.getHTML();
        updateDraft({ uid, groupKey, content, lastUpdated: newLastUpdated })
        .then(() => {
          setEditing(false);
        })
        .catch(() => {
          setEditing(false);
        })
      }, 2000);

    },
  });


  // Update doc from the database
  useEffect(() => {
    if (!draft || !draft.content || !draft.lastUpdated || draft.lastUpdated <= lastUpdated) {
      return;
    }
    console.log('update tiptap content', { draft }, { lastUpdated });
    editor.commands.setContent(draft.content);
  }, [draft, lastUpdated, editor]);


  if (loading) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" /> loading newsletter
      </div>
    )
  }


  return (
    <div>
      <TipTapMenu editor={editor} lastUpdated={draft && draft.lastUpdated ? draft.lastUpdated : null} editing={editing} />
      <EditorContent editor={editor} className="bg-white border-l-2 border-r-2 border-b-2 rounded-b-md p-3 content overflow-y-auto" />
    </div>
  );
}

Draft.propTypes = {
  groupKey: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
};


export default Draft;
