import React from 'react';
import { useContext } from 'react';
import { AuthContext, login, logout } from '../../database/auth';


function Header() {
  const { data: auth } = useContext(AuthContext);

  return (
    <div className="bg-gray-200">
      <div className="max-w-5xl mx-auto py-2 flex justify-between px-5">
        <div className="flex text-gray-800">
          <span className="font-bold" style={{color: '#0D5C63'}}>BYO</span>
          <span>.</span>
          <span className="italic">news</span>
        </div>
        <div className="flex text-sm text-gray-600 items-center">
          {
            !!auth
            && !!auth.email
            && (
              <div className="mr-3">
                { auth.email }
              </div>
            )
          }
          {
            !!auth
            && (
              <button
                type="button"
                className="inline-block leading-tight border hover:bg-gray-300 rounded px-2 py-1 focus:outline-none hover:text-gray-700"
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </button>
            )
          }

          {
            !auth
            && (
              <button
                type="button"
                className="inline-block leading-tight border hover:bg-gray-300 rounded px-2 py-1 focus:outline-none hover:text-gray-700"
                onClick={() => {
                  login();
                }}
              >
                Login / Sign up with Google
              </button>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Header;
