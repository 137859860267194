import moment from 'moment';
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useGroup } from '../../database/groups';
import Draft from './draft';
import Members from './members';


function Group() {
  const { groupKey } = useParams();
  const {loading, data: group} = useGroup(groupKey);

  if (loading) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" /> loading group
      </div>
    )
  }

  if (!group) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        Group not found.
      </div>
    )
  }

  // Group vars
  const { name: groupName, headerImage, members, deadline } = group;

  console.log('deadline.E < moment().isoWeekday()', deadline.E, moment().isoWeekday(), deadline.E < moment().isoWeekday());
  console.log('deadline.E === moment().isoWeekday()', deadline.E === moment().isoWeekday());
  console.log('deadline.HH > parseInt(moment().format(HH), 10)', deadline.HH > parseInt(moment().format('HH'), 10))

  // Calculate next upcoming deadline
  const deadlineMoment = deadline.E > moment().isoWeekday()
    || (
      deadline.E === moment().isoWeekday()
      && deadline.HH > parseInt(moment().format('HH'), 10)
    )
    ? moment().isoWeekday(deadline.E)
    : moment().add(1, 'weeks').isoWeekday(deadline.E);


  return (
    <div>

      <div className="mt-5 flex justify-between items-center">
        <div>
          <Link
            to="/"
            className="inline-block leading-tight hover:bg-gray-300 rounded px-2 py-1 focus:outline-none text-gray-600 hover:text-gray-700"
          >
            <i className="far fa-long-arrow-left mr-2"></i>
            Home
          </Link>
        </div>
      </div>

      <div
        className="z-10 my-2 rounded-lg relative w-full"
        style={{
          background: `linear-gradient(rgba(20,20,20, .5), rgba(20,20,20, .5)), url(${headerImage || 'https://unsplash.com/photos/0YP8RTiIv_g/download?force=true&w=1920'}) no-repeat center center`,
          backgroundSize: 'cover',
          overflow: 'hidden',
          // paddingTop: '18%',
        }}
      >
        <div className="w-full h-full top-0 left-0 flex justify-center items-center text-white">
          <div className="text-center my-16 px-5">
            <div className="text-xl lg:text-3xl font-bold">{ groupName }</div>
          </div>
        </div>
      </div>

      <div className="lg:flex">
        <div className="lg:w-3/5">
          <div className="mt-3 mb-5 font-bold">
            {
              !!deadline
              && !!deadline.E
              && !!deadline.HH
              && `Deadline: ${deadlineMoment.format('dddd, MMMM D')} @ ${deadline.HH}:00`
            }
          </div>
          <Draft groupKey={groupKey} members={members} />
        </div>
        <div className="mt-10 lg:mt-0 lg:w-2/5 ml-5">
          <Members members={members} />
        </div>
      </div>
    </div>
  );
}

export default Group;
